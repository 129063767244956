<style lang="scss">
.action-card {
  //min-height: 70vh;
  max-width: 275px;
}
</style>
<!--eslint-disable-->
<template>
  <div>

    <b-dropdown id="dropdown-grouped" block variant="primary" right text="Manage" class="manage-btn" >
      <b-dropdown-group id="dropdown-group-1" header="User Actions">

        <b-dropdown-item v-if="$can('Control', 'Device')" @click="showModal('modal-reboot-device')">
          <i class="fas fa-sync fa-fw"></i> Reboot
        </b-dropdown-item>

        <b-dropdown-item v-if="$can('Control', 'Device')" @click="showModal('modal-lock-device')">
          <i class="fas fa-lock fa-fw"></i> Lock
        </b-dropdown-item>

        <b-dropdown-item v-if="device.owner_uid !== '' && $can('Control', 'Device')" @click="showModal('modal-detach')">
          <i class="fas fa-user-times fa-fw"></i> Deallocate
        </b-dropdown-item>

        <b-dropdown-item v-if="$can('Delete', 'Device')" @click="showModal('modal-delete-device')">
          <i class="fas fa-broom fa-fw"></i> Wipe
        </b-dropdown-item>

        <b-dropdown-item v-if="$can('Control', 'Device') & !device.disabled" @click="showModal('modal-disable-device')">
          <i class="fas fa-ban fa-fw"></i> Disable
        </b-dropdown-item>
        <b-dropdown-item v-if="$can('Control', 'Device') & device.disabled" @click="showModal('modal-enable-device')">
          <i class="fas fa-check fa-fw"></i> Enable
        </b-dropdown-item>

      </b-dropdown-group>

      <div v-if="$can('Manage', 'All')">
        <b-dropdown-divider />
        <b-dropdown-group id="dropdown-group-2" header="Admin Functions">
          <b-dropdown-item v-if="$can('Control', 'Device')" @click="showModal('modal-isolate-device')">
            <i class="fas fa-sync fa-fw"></i> Isolate Policy
          </b-dropdown-item>
        </b-dropdown-group>
      </div>

    </b-dropdown>


    <b-modal id="modal-reboot-device" title="" hide-footer>
      <warning-modal title="Are you sure you wish to reboot this device?" ok-variant="success"
                     @ok="rebootDevice()"
                     @close="closeModals()"/>
    </b-modal>

    <b-modal id="modal-lock-device" title="" hide-footer>
      <warning-modal title="Are you sure you wish to lock this device?" ok-variant="success"
                     @ok="lockDevice()"
                     @close="closeModals()"/>
    </b-modal>

    <b-modal id="modal-delete-device" title="" hide-footer>
      <wipe-device-modal title="Are you sure you wish to wipe this device?" :ownerUid="device.owner_uid" ok-variant="danger"
                         @ok="deleteDevice()"
                         @close="closeModals()">
      </wipe-device-modal>
    </b-modal>

    <b-modal id="modal-isolate-device" title="" hide-footer>
      <warning-modal title="Are you sure you wish to isolate the policy on this device?" ok-variant="success"
                     @ok="isolateDevice()"
                     @close="closeModals()"/>
    </b-modal>


    <b-modal id="modal-detach" title=""  hide-footer>
      <detach-modal @ok="deallocateDevice()" @close="closeModals()">
      </detach-modal>
    </b-modal>

    <b-modal id="modal-disable-device" title="" hide-footer>
      <disable-device-modal title="Are you sure you wish to disable this device?" ok-variant="danger"
                         @ok="disableDevice"
                         @close="closeModals()">
      </disable-device-modal>
    </b-modal>

    <b-modal id="modal-enable-device" title="" hide-footer>
      <enable-device-modal title="Are you sure you wish to enable this device?"
                            @ok="enableDevice()"
                            @close="closeModals()">
      </enable-device-modal>
    </b-modal>

  </div>
</template>
<!--eslint-enable-->

<script>
import WipeDeviceModal from '@/views/devices/viewDevice/sections/WipeDeviceModal.vue'
import DisableDeviceModal from '@/views/devices/viewDevice/sections/DisableDeviceModal.vue'
import EnableDeviceModal from '@/views/devices/viewDevice/sections/EnableDeviceModal.vue'
import DetachModal from '@/views/devices/viewDevice/sections/DetachModal.vue'
import WarningModal from '../../../../components/modals/WarningModal.vue'
import DeviceService from '../../../../services/DeviceService'

export default {
  name: 'Actions',
  components: {
    WarningModal,
    WipeDeviceModal,
    DetachModal,
    DisableDeviceModal,
    EnableDeviceModal
  },
  props: {
    device: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  mounted() {},
  methods: {
    closeModals() {
      this.$bvModal.hide('modal-reboot-device')
      this.$bvModal.hide('modal-lock-device')
      this.$bvModal.hide('modal-delete-device')
      this.$bvModal.hide('modal-switch-group-device')
      this.$bvModal.hide('modal-isolate-device')
      this.$bvModal.hide('modal-detach')
      this.$bvModal.hide('modal-disable-device')
      this.$bvModal.hide('modal-enable-device')
    },
    showModal(modalName) {
      this.$bvModal.show(modalName)
    },
    rebootDevice() {
      this.closeModals()
      this.loading = true
      DeviceService.rebootDevice(this.device.uid)
        .then(() => {
          this.$toast.success('Reboot command sent to device Successfully', {
            toastClassName: ['toast-std', 'success-toast'],
          })
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not reboot device, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    lockDevice() {
      this.closeModals()
      this.loading = true
      DeviceService.lockDevice(this.device.uid)
        .then(() => {
          this.$toast.success('Lock command sent to device Successfully', {
            toastClassName: ['toast-std', 'success-toast'],
          })
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not lock device, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    deleteDevice() {
      this.closeModals()
      this.loading = true
      DeviceService.deleteDevice(this.device.uid)
        .then(() => {
          this.$toast.success('Deleted device Successfully', {
            toastClassName: ['toast-std', 'success-toast'],
          })
          this.$router.push({ name: 'devices' }) // redirect since device does not exist anymore
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not delete device, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    disableDevice(notes){
      this.closeModals()
      this.loading = true

      DeviceService.disableDevice(this.device.uid, notes)
        .then(() => {
          this.$toast.success("Device disabled Successfully", {
            toastClassName: ["toast-std", "success-toast"],
          });

          this.$emit('refresh')
        }).catch(err => {
        const res = err.response;
        let errorText = "Could not disable device, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }
        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });

        this.$emit('refresh')
      })
        .finally(() => {
          this.loading = false;
        });
    },
    enableDevice() {
      this.closeModals()
      this.loading = true

      DeviceService.enableDevice(this.device.uid, true)
        .then(() => {
          this.$toast.success("Device enabled Successfully", {
            toastClassName: ["toast-std", "success-toast"],
          });

          this.$emit('refresh')
        }).catch(err => {
        const res = err.response;
        let errorText = "Could not enable device, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }
        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });

        this.$emit('refresh')
      })
        .finally(() => {
          this.loading = false;
        });
    },
    isolateDevice() {
      this.closeModals();
      this.loading = true;

      DeviceService.isolateDevice(this.device.uid)
        .then(() => {
          this.$toast.success("Device policy isolated Successfully", {
            toastClassName: ["toast-std", "success-toast"],
          });

          this.$emit('refresh')
        }).catch(err => {
          const res = err.response;
          let errorText = "Could not isolate device, please refresh and try again";

          if (res && res.data.error) {
            errorText = res.data.error;
          }
          this.$toast.error(errorText, {
            toastClassName: ["toast-std", "warning-toast"],
          });

          this.$emit('refresh')
        })
        .finally(() => {
          this.loading = false;
        });

    },
    deallocateDevice() {
      this.loading = true
      DeviceService.detachOwner(this.device.uid)
        .then(() => {

          this.closeAndRefresh()

          this.$toast.success('The device has been Deallocated', {
            toastClassName: ['toast-std', 'success-toast'],
          })
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not deallocate device, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    closeAndRefresh() {
      this.closeModals()
      this.$emit('refresh')
    },
  },
}
</script>
